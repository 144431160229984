var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "item-requiring-attention-information-modal",
      attrs: {
        id: "item-requiring-attention-information-modal",
        title: "Why Do I need to Provide This Information?",
        size: "lg",
        centered: "",
        "hide-footer": "",
      },
    },
    [
      _c("p", [
        _vm._v(
          "As your company’s Registered Agent, Wyoming statutes (§ 17-16-205 and § 17-28-107) require us to keep record of a few key details:"
        ),
      ]),
      _c("ul", [
        _c("li", [
          _vm._v(
            "\n      the names and physical addresses of all directors, officers, limited liability company managers, managing partners, trustees or persons serving in a similar capacity for this business\n    "
          ),
        ]),
        _c("li", [
          _vm._v(
            "\n      the name, physical address, email, and business telephone number of a natural person who is authorized to receive communications from the registered agent for this business (referred to here as a Communications Contact)\n    "
          ),
        ]),
      ]),
      _c("p", [
        _vm._v(
          "Please provide us with current information in the provided form."
        ),
      ]),
      _c("p", [
        _c("b", [
          _vm._v(
            "You are responsible for ensuring the information we have is current and accurate. Inaccurate filings can potentially result in monetary penalties from the state. If we do not receive any correspondence from you within 90 days before your Annual Report due date, then we will presume that there has been 'no change' to your entity's information."
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "text-right" },
        [
          _c(
            "b-button",
            {
              staticClass: "submit-btn-width",
              attrs: {
                variant: "primary",
                "aria-label": "I understand button",
              },
              on: { click: _vm.close },
            },
            [_vm._v("\n      I understand\n    ")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }